Vue.prototype.filters = {
    priceWithSup(price, removeZeros = false) {
        if (!price) {
            return null
        }

        const [sign, amount, cents, currency] = this._parsePrice(price)

        let result = `<sup>${sign}</sup>${amount}`
        if (!(removeZeros && cents == '00')) {
            result += `<sup>${cents}</sup>`
        }
        if (currency && currency != 'USD') {
            result += ` ${currency}`
        }

        return result
    },

    priceRemoveZeros(price) {
        if (!price) {
            return null
        }

        const [sign, amount, cents, currency] = this._parsePrice(price)

        let result = `${sign}${amount}`
        if (cents != '00') {
            result += `.${cents}`
        }
        if (currency && currency != 'USD') {
            result += ` ${currency}`
        }

        return result
    },

    getPriceWithCurrency: function (price, currency) {
        let negative = false;
        let result = '';

        if (price < 0) {
            negative = true;
            price = Math.abs(price);
        }

        price = price.toFixed(2);

        switch (currency) {
            case 'EUR':
                result = `'&euro;${price} CAD`;
                break;

            case 'CAD':
                result = `$${price} CAD`;
                break;

            case 'USD':
            default:
                result = `$${price}`;
                break;
        }

        if (negative) {
            result = '-' + result;
        }

        return result;
    },

    _parsePrice(price) {

        // $99.50 CAD
        let sign = price.charAt(0) // $
        price = price.slice(1, price.length) // 99.50 CAD

        let [amount, centsWithCurrency] = price.split('.') // [99, 50 CAD]
        let [cents, currency] = centsWithCurrency.split(' ') // [50, CAD]

        if (currency === undefined) {
            currency = 'USD'
        }
        return [sign, amount, cents, currency]
    },

    pluralize(number, word) {
        return (number > 1) ? word + 's' : word;
    },

    // test
    drawStarsWithText(customersRating, reviewsCount = 0) {
        let intScore = parseInt(customersRating, 10); // 3.7 -> 3
        let remainingScore = customersRating - intScore; // 3.7 - 3 = 0.7
        let emptyStars = 5 - intScore; // 5 - 3 = 2

        let html = '';

        for (var i = 0; i <= intScore; i++) { // draw number of stars determined by integer. <= is used for callback
            if (i == intScore) {
                if (remainingScore < 0.25) { // if the remainder is less than 0.25, do not draw any stars
                    html = this._createEmptyStar(emptyStars, html);
                }

                else if (remainingScore >= 0.25 && remainingScore <= 0.75) { // if it's between 0.25 and 0.75, draw a half star.
                    html += '<i class="fas fa-star half"></i> ';
                    html = this._createEmptyStar(emptyStars - 1, html);
                }

                else if (remainingScore > 0.75) { // if greater than 0.75, draw a full star
                    html += '<i class="fas fa-star"></i> ';
                    html = this._createEmptyStar(emptyStars - 1, html);
                }

            } else {
                html += '<i class="fas fa-star"></i> ';
            }
        }

        if (reviewsCount) {
            html += `
                <span>
                    <strong>${this.getWordMark(customersRating)}</strong>
                    (${reviewsCount} ${ this.pluralize(reviewsCount, 'Rating') })
                </span>`
        }
        return html;
    },

    _createEmptyStar(emptyStars, html) { // this function draws empty stars so that there are a total of 5 stars
    	if (emptyStars != 5) {
		    for (var i = 0; i < emptyStars; i++) {
			    html += '<i class="fas fa-star empty"></i> ';
		    }
	    }

        return html;
    },

    getWordMark(rating) {
        if (rating > 4.25) {
            return 'Excellent';
        }
        if (rating > 3.75) {
            return 'Great';
        }
        if (rating > 3.25) {
            return 'Good';
        }

        return '';
    },
	
	friendlyDate(date) {
		return (date != null && date != '') ? moment(date, 'YYYY-MM-DD').format(APR_DATE_FORMAT) : null;
	},

    hotelUrl(slug, source = false) {

        let url = `${apr.app_url}/psf/${slug}`

        if (source) {
            url += `?source=${source}`
        }

        return url
    },

    currency(item) {
        return money.formatMoney(item);
    },

    formatMessage: function (messages) {
        let string = '';
        if ('object' === typeof messages) {
            for (var index in messages) {
                string += messages[index] + ' ';
            }

            return string;
        }

        return messages;
    },

    dates: {
        survey: function (date) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format('MMMM Do, YYYY');
        },

        searchbox: function (date) {
            return moment(date, "MM/DD/YYYY").format('MMM D');
        },

        searchbox2: function (date) {
            return moment(date, "YYYY-MM-DD").format('MMM D');
        },

        searchbox3: function (date) {
            return moment(date, "YYYY-MM-DD").format('ddd MMM D');
        },

        searchbox4: function (date) {
            return moment(date, "MM/DD/YYYY").format('MMM D, YYYY');
        },

        searchbox5: function (date) {
            return moment(date, "YYYY-MM-DD").format('MMM D, YYYY');
        },

        checkout: function (date) {
            return moment(date, "YYYY-MM-DD").format('ddd, MMM D');
        },

        dateWithTime: function (date) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format('MM/DD/YYYY hh:mm A'); // 03/28/2017 06:30 AM
        },

        dateWithoutTime: function (date) {
            return moment(date, "YYYY-MM-DD").format('MM/DD/YYYY'); // 03/28/2017
        }
    },

    getFullNameByPackageType($type) {
        if ($type === 'PSF' || $type === 'PSC') {
            return 'Sleep at Beginning of Trip';
        }

        if ($type === 'PFS' || $type === 'PFS') {
            return 'Sleep at End of Trip';
        }

        if ($type === 'HRO') {
            return 'Hotel Room Only';
        }

        return 'Sleep at Beginning & End of Trip';
    }
};

var stars = {
    createStars: function (integer, remainder, emptyStars, lot) { // this function draws full stars
        for (var i = 0; i <= integer; i++) { // draw number of stars determined by integer. <= is used for callback
            if (i == integer) {
                stars.createRemainderStar(remainder, emptyStars, lot); // run this function after for loop complete.
            } else {
                lot.append('<i class="fas fa-star"></i>');
            }
        }
    },

    createRemainderStar: function (remainder, emptyStars, lot) { // this function determines if the decimal requires a whole star or half star
        if (remainder < 0.25) { // if the remainder is less than 0.25, do not draw any stars
            stars.createEmptyStar(emptyStars, lot);
        }

        else if (remainder >= 0.25 && remainder <= 0.75) { // if it's between 0.25 and 0.75, draw a half star.
            lot.append('<i class="fas fa-star-half-alt"></i>');
            emptyStars = emptyStars - 1;
            stars.createEmptyStar(emptyStars, lot);
        }

        else if (remainder > 0.75) { // if greater than 0.75, draw a full star
            lot.append('<i class="fas fa-star"></i>');
            emptyStars = emptyStars - 1;
            stars.createEmptyStar(emptyStars, lot);
        }
    },

    createEmptyStar: function (emptyStars, lot) { // this function draws empty stars so that there are a total of 5 stars
        for (var i = 0; i < emptyStars; i++) {
            lot.append('<i class="fas fa-star-half-alt"></i>');
        }
    },
};

Vue.filter('roundToWholeDollar', function(price) {
    let negative = false;

    if (price < 0) {
        negative = true;
        price = Math.abs(price);
    }

    price = price.toFixed(0);

    if (negative) {
        price = '-' + price;
    }

    return price;
});

Vue.filter('pluralize', function (number, word, pl){
    if(isNaN(number) || Math.abs(number)=== 1) return word;
    if(!pl) return word+'s';
    return word.replace(/\S+(\s*)$/, pl+'$1');
});

Vue.filter('friendlyDate1', function (date) {
	return (date != null && date != '') ? moment(date, 'MMM DD, YYYY').format('ddd, MMM D') : null;
});

Vue.filter('friendlyDate2', function (date) {
	return (date != null && date != '') ? moment(date, 'YYYY-MM-DD').format('ddd, MMM D') : null;
});

Vue.filter('getShuttleIconClass', function (shuttleOption) {
    if (shuttleOption === 'Free Ride Share' || shuttleOption === 'Free Taxi') {
        return 'fa-taxi';
    }
    if (shuttleOption === 'Walk to Airport') {
        return 'fa-male';
    }
    if (shuttleOption === 'Metro to Airport') {
        return 'fa-train';
    }
    return 'fa-map-signs';
});

Vue.filter('getNewShuttleIconClass', function (shuttleOption) {
    if (shuttleOption === 'Free Ride Share' || shuttleOption === 'Free Taxi' || shuttleOption === 'Free Shuttle') {
        return 'apr-icon-bus-2-line';
    }
    if (shuttleOption === 'Walk to Airport') {
        return 'apr-icon-walk-line';
    }
    if (shuttleOption === 'Metro to Airport') {
        return 'apr-icon-train-line';
    }
    return 'apr-icon-parking-box-line';
});