<template>
    <div>
        <slick-carousel ref="mainSlider" class="product-modal-slider" :options="mainSliderOptions" @beforeChange="syncSliders">
            <slot name="mainSlider"></slot>
        </slick-carousel>
        <slick-carousel
            ref="previewSlider"
            class="product-preview-slider"
            :options="previewSliderOptions"
            @beforeChange="syncSliders"
            @click.native="goToSlide"
        >
            <slot name="previewSlider"></slot>
        </slick-carousel>
    </div>
</template>

<script>
import Slick from 'vue-slick'
export default {
    components: {
        'slick-carousel': Slick,
    },
    data: function () {
        return {
            mainSliderOptions: {
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: false,
                asNavFor: this.$refs.previewSlider,
                initialSlide: 7,
            },
            previewSliderOptions: {
                autoplay: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: false,
                focusOnSelect: true,
                swipeToSlide: true,
                asNavFor: this.$refs.mainSlider,
                initialSlide: 7,
            },
        }
    },
    methods: {
        syncSliders(event, slick, currentPosition, nextPosition) {
            this.$refs.mainSlider.goTo(nextPosition)
            this.$refs.previewSlider.goTo(nextPosition)
        },
        goToSlide(e) {
            const target = e.target
            const targetSlide = target.closest('[data-slick-index]')
            if (targetSlide) {
                const targetIndex = targetSlide.getAttribute('data-slick-index')
                if (targetIndex) {
                    this.$refs.mainSlider.goTo(targetIndex)
                }
            }
        },
    },
}
</script>

<style lang="scss">
.product-modal-slider {
    margin-bottom: 1rem;
    position: relative;
    z-index: 10;
    .slick-slide {
        max-height: 500px !important;
        img {
            max-height: 500px;
        }
    }
    .slick-prev,
    .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 44px;
        background: #fff;
        z-index: 3;
        border-radius: 50%;
        &.slick-disabled {
            opacity: 0.8;
            pointer-events: none;
        }
        &:before {
            content: '';
            width: 19px;
            height: 20px;
            display: block;
            opacity: 1;
        }
    }
    .slick-prev {
        left: -22px;
        &:before {
            margin-right: 4px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999999 10L4.48046 6.66667L6.93031 4.32039L11.4414 4.85949e-07L14.9219 3.33333L10.4277 7.63754L17 7.63754L17 12.3463L10.4277 12.3463L14.9219 16.6667L11.4414 20L6.93031 15.6796L4.48047 13.3333L0.999999 10Z' fill='%23B4E076'/%3E%3C/svg%3E%0A");
        }
    }
    .slick-next {
        right: -22px;
        &:before {
            margin-left: 2px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 10L14.5195 13.3333L12.0697 15.6796L7.55861 20L4.07814 16.6667L8.57233 12.3625H2L2 7.65372L8.57233 7.65372L4.07814 3.33333L7.55861 0L12.0697 4.32039L14.5195 6.66667L18 10Z' fill='%23B4E076'/%3E%3C/svg%3E%0A");
        }
    }
}

.product-preview-slider {
    position: relative;
    z-index: 10;
    .slick-list {
        margin: 0 -4px;
    }
    .slick-slide {
        padding: 0 4px;
    }
}

.product-preview-slider,
.product-modal-slider {
    .slick-track {
        display: flex;
        align-items: stretch;
    }
    .slick-slide {
        height: auto;
        & > div,
        img {
            height: 100%;
        }
        img {
            position: relative;
            width: 100%;
            object-fit: cover;
            object-position: center;
            background-color: #eee;
        }
    }
}
</style>
