import Modal from './../../components/BsModal.vue'
import PaymentContainerPsf from '../../components/Checkout/PaymentContainerPsf'

require('jquery.payment')

Vue.component('page-psf-checkout', {
    components: {Modal, PaymentContainerPsf},
    mixins: [],
    computed: {
        fraudData: function () {
            return window.fraud_data;
        },
    },

    watch: {
        coupon() {
            this.modalErrorMessage = false
        },

        personal: {
            handler: function () {
                this.addPersonalToSession(this)
            },
            deep: true
        },

        'card.number_displayed': {
            handler: function () {
                let self = this

                setTimeout(function () {
                    self.card.number_displayed = $("#card-number").val()

                    let value = self.card.number_displayed
                    self.card.number = value.replace(/ /g, '');

                    self.paymentShowCreditCardIcon();
                }, 50)
            },
        },
    },

    data: function () {
        return {
            search: _.get(window, 'apr.search'),
            checkout: _.get(window, 'apr.checkout'),
            hotel: _.get(window, 'apr.hotel'),
            descriptions: _.get(window, 'apr.descriptions'),
            pricing: _.get(window, 'apr.pricing'),
            airportCode: _.get(window, 'apr.search.airport.code'),

            available: _.get(window, 'apr.availability.available', true),
            unavailabilityType: _.get(window, 'apr.availability.unavailability_type', true),
            unavailabilityError: _.get(window, 'apr.availability.unavailability_error', true),

            months: apr.months,
            years: apr.years,
            customerId: apr.customer_id,
            provider: _.get(window, 'apr.checkout.provider'),

            step: 1,
            inProgressCoupon: false,
            inProgressReservation: false,

            personal: {
                email: '',
                firstname: '',
                lastname: '',
                phone: '',
                address: '',
                city: '',
                state: '',
                zipcode: '',
                comments: '',
            },
            card: {
                number: '',
                number_displayed: '',
                exp_month: '',
                exp_year: '',
                cvc: '',

                gateway: null,
                gateway_data: null,
                device_data: null,
            },
            meta_data: {
                request_id: '',
                fraud_chance: '',
            },

            beds: _.get(window, 'apr.beds'),
            bed_id: '',

            showPreferences: false,
            showTaxes: false,
            showCouponForm: false,

            coupon: null,
            couponErrorMessage: null,

            headerTitle: 'Guest details',

            errors: [],
            isiPad: (navigator.userAgent.match(/iPad/i) != null)
        }

    },

    mounted() {
        this.initPopover();

        if (this.isiPad) {
            $("#card-number, #card-code").prop("type", "number")
        } else {
            this.paymentMasks()
        }

        this.$root.$on('gateway-changed', (data) => {
            this.card.gateway = data.gateway;
            this.card.gateway_data = data.gateway_data;
            this.card.device_data = data.device_data;
        });

        if (apr.personal) {
            $.extend(this.personal, apr.personal);

            if (this.personal.comments) {
                this.showPreferences = true
            }

            if (this.customerId) {
                //dataLayer - checkout3 - Customer Details
                let category = apr.search.package === 'PSF'
                    ? "Hotel & Parking Package"
                    : "";
                let variant = typeof(this.available) != 'undefined' && this.available === true
                    ? "available"
                    : "sold out";
                let price = typeof(this.pricing.sub_total) != 'undefined'
                    ? this.pricing.sub_total
                    : typeof(this.pricing.package_total) != 'undefined'
                        ? this.pricing.package_total
                        : "";
                dataLayer.push({
                    'event': 'checkout',
                    'ecommerce': {
                        'checkout': {
                            'actionField': {'step': 3, 'option': 'Customer Details'},
                            'products': [{
                                'name': `PSF ${this.airportCode} ${this.hotel.name}`,
                                'id': `PSF ${this.airportCode}`,
                                'price': price,
                                'brand': '',
                                'category': category,
                                'variant': variant,
                                'quantity': 1
                            }]
                        }
                    }
                })

                this.step = 2
                this.headerTitle = 'Customer details'
                this.triggerCheckoutEvent('Step', 2)
            } else {
                this.triggerCheckoutEvent('Step', 1)
            }
        }
    },

    methods: {
        addPersonalToSession() {
            this.$http.post(apr.add_personal_link, {personal: this.personal})
        },

        nextStep() {
            this.errors = {};

            if (!this.isValidEmail(this.personal.email)) {
                this.errors['personal.email'] = ['Invalid email address'];
            }
            if (!this.personal.firstname) {
                this.errors['personal.firstname'] = ['Empty first name'];
            }
            if (!this.personal.lastname) {
                this.errors['personal.lastname'] = ['Empty last name'];
            }
            if (!this.personal.phone) {
                this.errors['personal.phone'] = ['Empty phone number'];
            }

            if (_.size(this.errors) === 0) {
                //dataLayer - checkout step3 - Customer Details
                let category = apr.search.package === 'PSF'
                    ? "Hotel & Parking Package"
                    : "";
                let variant = typeof(this.available) != 'undefined' && this.available === true
                    ? "available"
                    : "sold out";
                let price = typeof(this.pricing.sub_total) != 'undefined'
                    ? this.pricing.sub_total
                    : typeof(this.pricing.package_total) != 'undefined'
                        ? this.pricing.package_total
                        : "";
                dataLayer.push({
                    'event': 'checkout',
                    'ecommerce': {
                        'checkout': {
                            'actionField': {'step': 3, 'option': 'Customer Details'},
                            'products': [{
                                'name': `PSF ${this.airportCode} ${this.hotel.name}`,
                                'id': `PSF ${this.airportCode}`,
                                'price': price,
                                'brand': '',
                                'category': category,
                                'variant': variant,
                                'quantity': 1
                            }]
                        }
                    }
                })

                this.triggerCheckoutEvent('Step', 2);
                this.step++;
            }
        },

        submitReservation() {
            this.checkFraudChance(this.fraudData);
            this.$root.$emit('checkout::reservation-submitted', this.card);

            if (this.provider === 'travelport' || this.provider === 'expedia') {
                setTimeout(() => {
                    this.makeReservationRequest();
                }, 500);

            } else {
                this.makeReservationRequest();
            }
        },

        makeReservationRequest() {
            if (this.inProgressReservation) {
                return false
            }
            this.inProgressReservation = true

            this.triggerCheckoutEvent('Complete Reservation')

            this.$http.post(_.get(window, 'apr.complete_reservation_link'), this.getReservationParams()).then((response) => {
                let successResponse = _.get(response, 'body.data');
                let redirectUrl = _.get(successResponse, 'receipt_url');

                if (redirectUrl) {
                    //dataLayer - checkout step4 - Payment Method
                    let category = apr.search.package === 'PSF'
                        ? "Hotel & Parking Package"
                        : "";
                    let variant = typeof(this.available) != 'undefined' && this.available === true
                        ? "available"
                        : "sold out";
                    let price = typeof(this.pricing.sub_total) != 'undefined'
                        ? this.pricing.sub_total
                        : typeof(this.pricing.package_total) != 'undefined'
                            ? this.pricing.package_total
                            : "";
                    dataLayer.push({
                        'event': 'checkout',
                        'ecommerce': {
                            'checkout': {
                                'actionField': {'step': 4, 'option': 'Payment Method'},
                                'products': [{
                                    'name': `PSF ${this.airportCode} ${this.hotel.name}`,
                                    'id': `PSF ${this.airportCode}`,
                                    'price': price,
                                    'brand': '',
                                    'category': category,
                                    'variant': variant,
                                    'quantity': 1
                                }]
                            }
                        }
                    })

                    window.location.href = redirectUrl;
                } else {
                    this.inProgressReservation = false;
                }

            }, (response) => {
                this.inProgressReservation = false

                let redirectUrl = _.get(response, 'body.data.redirect');
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                }

                let errors = _.get(response, 'body.errors');
                if (errors) {
                    if (_.get(errors, 'other')) { // psf gateway validator
                        this.errors = errors.other.errors;
                        this.triggerCheckoutEvent("Payment Gateway Error PSF", JSON.stringify(_.get(errors, 'other.errors')));

                    } else { // psf validators
                        this.errors = errors;
                        this.triggerCheckoutEvent("Validation Error from PSF");
                    }

                } else { // apr validators
                    this.errors = response.body
                    this.triggerCheckoutEvent("Validation Error from APR");
                }
            })
        },

        getReservationParams() {
            let params = {
                personal: this.personal,
                card: this.card,
                meta_data: this.meta_data,
            };

            // for backend validation (required if present)
            if (this.beds.length) {
                params.bed_id = this.bed_id;
            }

            return params
        },

        triggerCheckoutEvent(action, label = '') {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'PSF Checkout',
                'EventAction': action,
                'EventLabel': label
            });
        },

        toggleCoupon() {
            this.showCouponForm = !this.showCouponForm;
        },

        applyCoupon() {
            if (this.coupon) {
                this.inProgressCoupon = true;

                this.$http.post(_.get(window, 'apr.add_coupon_link'), {coupon: this.coupon}).then((response) => {
                    this.pricing = _.get(response, 'body.data.pricing');

                    this.inProgressCoupon = false;
                    this.trackCoupons('Success');

                }, (response) => {
                    this.couponErrorMessage =
                        _.get(response, 'body.errors.coupon.0',
                            _.get(response, 'body.errors',
                                _.get(response, 'body.error')
                            )
                        );

                    this.inProgressCoupon = false;
                    this.trackCoupons('Failed');
                });

            } else {
                this.couponErrorMessage = 'Please enter a coupon code';
            }
        },

        trackCoupons(status) {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'PSF Coupon',
                'EventAction': status,
                'EventLabel': this.coupon
            })
        },

        isValidEmail(email) {
            return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
        },

        checkFraudChance(fraud_data) {
            if (!apr.device_fingerprint.enabled) {
                return;
            }

            let redirectPath = '/';

            if (typeof fraud_data !== "undefined" && fraud_data.request_id !== '' && fraud_data.fraud_chance !== '') {
                if (fraud_data.fraud_chance >= apr.device_fingerprint.fraud_mark) {
                    window.location.href = redirectPath;
                }

                this.meta_data.request_id = String(fraud_data.request_id);
                this.meta_data.fraud_chance = String(fraud_data.fraud_chance);
            } else {
                window.location.href = redirectPath;
            }
        },

        clearCouponError() {
            this.inProgressCoupon = false
            this.couponErrorMessage = null
        },

        clearErrors(name) {
            this.errors[name] = null
        },

        paymentMasks: function () {
            $('#card-number').payment('formatCardNumber');
            $('#card-cvc').payment('formatCardCVC');
        },

        paymentShowCreditCardIcon: function () {
            let number = this.card.number
            let firstDigit = number.charAt(0)
            let $creditIcon = $('.credit-card-icon')
            let card = ''

            if (firstDigit == 4) {
                card = '<i class="fab fa-cc-visa"></i>';

            } else if (firstDigit == 5) {
                card = '<i class="fab fa-cc-mastercard"></i>';

            } else if (firstDigit == 3) {
                card = '<i class="fab fa-cc-amex"></i>';

            } else if (firstDigit == 6) {
                card = '<i class="fab fa-cc-discover"></i>';
            }

            $creditIcon.html(card);
        },

        initPopover() {
            $('[data-toggle="popover"]').popover({
                container: '#psf-checkout',
                template:
                    `<div class="popover" role="tooltip">
                        <div class="arrow"></div>
                        <div class="close"><span>&times;</span></div>
                        <div class="popover-body"></div>
                    </div>`
            });

            $('body').on('click', function (e) {
                $('[data-toggle="popover"]').each(function () {
                    if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                        $(this).popover('hide');
                    }
                });
            });

            $(document).on('click', '.close', {}, (e) => {
                $(e.target).closest('.popover').popover('hide')
            })
        },

        parseErrorMsg(field){
            if(this.errors[field] && this.errors[field][0]) {
                let errorMsg = this.errors[field][0]
                switch (field) {
                    case 'card.number':
                        return errorMsg.replace(field + ' field', 'card number');
                        break;
                    case 'card.exp_month':
                        return errorMsg.replace('card.exp month field', 'card expiration month');
                        break;
                    case 'card.exp_year':
                        return errorMsg.replace('card.exp year field', 'card expiration year');
                        break;
                    case 'card.cvc':
                        return errorMsg.replace(field + ' field', 'CVC');
                        break;
                    case 'billing.address':
                        return errorMsg.replace(field + ' field', 'address');
                        break;
                    case 'billing.city':
                        return errorMsg.replace(field + ' field', 'city');
                        break;
                    case 'billing.state':
                        return errorMsg.replace(field + ' field', 'state');
                        break;
                    case 'billing.zipcode':
                        return errorMsg.replace(field + ' field', 'zipcode');
                        break;
                    default:
                        return errorMsg;
                }
            }
        }
    }
});
