export default {
    methods: {
        trackImpressions() {
            let impressions = [];
            let position = 1;

            // Available
            _.forEach(this.search, (item) => {
                let category = item.provider === 'APR'
                    ? "Airport Parking Only"
                    : item.provider === 'PSF'
                        ? "Hotel & Parking Package"
                        : "";
                let price = item.rate != 'undefined' ? item.rate : '';
                const prefix = (item.provider === 'PSF') ? '(APRPSF) ' : '';
                const product = {
                    'name': `${prefix}${this.airport.code} ${item.name}`,
                    'id': this.airport.code,
                    'price': price,
                    'brand': '',
                    'category': category,
                    'variant': 'available',
                    'list': 'Search Results',
                    'position': position,
                };

                impressions.push(product);
                position++;
            });

            // console.log('Available: ' + _.filter(impressions, {'variant': 'available'}).length);

            // Soldout
            _.forEach(this.soldout, (item) => {
                let category = item.provider === 'APR'
                    ? "Airport Parking Only"
                    : item.provider === 'PSF'
                        ? "Hotel & Parking Package"
                        : "";
                let price = typeof item.rate != 'undefined' ? item.rate : '';
                const product = {
                    'name': `${this.airport.code} ${item.name}`,
                    'id': this.airport.code,
                    'price': price,
                    'brand': '',
                    'category': category,
                    'variant': 'sold out',
                    'list': 'Search Results',
                    'position': position,
                };

                impressions.push(product);
                position++;
            });

            // console.log('Sold out: ' + _.filter(impressions, {'variant': 'sold out'}).length);

            //dataLayer - impressions
            dataLayer.push({
                'event': 'impressions',
                'ecommerce': {
                    'currencyCode': 'USD',
                    'impressions': impressions
                }
            });
        },
    }
}