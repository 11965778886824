<template>
    <div>
		<div id="dropin-container"></div>
<!--		<button class="btn btn-sm btn-link text-center w-50" @click.prevent="deletePaymentMethod()" v-if="resettablePayment">Choose another way to pay</button>-->
	</div>
</template>

<script>
	import BraintreeDropIn from 'braintree-web-drop-in'

    export default {
        data: function () {
            return {
	            resettablePayment: false,
                instance: null,
            }
        },

        mounted() {
        	setTimeout(() => {
		        this.initBraintreeContainer();
			}, 500)

            this.$root.$on(`checkout::reservation-submitted`, () => {
                this.requestPaymentMethod();
            });

	        this.$root.$on(`payment-container::reset`, () => {
                this.reinitBraintreeContainer();
            });
        },

        methods: {
            reinitBraintreeContainer() {
                if(this.instance) {
                    this.instance.teardown();
                    this.initBraintreeContainer();
                }
            },

            initBraintreeContainer() {
							const that = this;
	            BraintreeDropIn.create({
					authorization: window.apr.braintree_client_token,
					container: '#dropin-container',
					dataCollector: {
						kount: true
					},
					paypal: {
						flow: 'checkout',
						amount: this.$parent.pricing.pay_now_usd,
						currency: 'USD',
					},
					card: {
						overrides: {
							fields: {
								cvv: {
									placeholder: ' ',
								},
								number: {
									placeholder: '',
								},
								expirationDate: {},
							},
							styles: {
								'input': {
									'font-size': '0.875rem',
									'font-weight': '500',
									'color': '#5a5e6b',
									'font-family': '"Lato", sans-serif'
								},
								'input:focus': {
									'color': '#5a5e6b',
								},
								'.invalid': {}
							}
						}
					}
				}, (createErr, instance) => {
					if (!createErr) {
						this.instance = instance;

						// Listeners to Braintree events
						if (instance.isPaymentMethodRequestable()) {
							that.$emit('update-submit-disabled-state', false);
						}
						instance.on('paymentMethodRequestable', function (event) {
							that.$emit('update-submit-disabled-state', false);
						});
						instance.on('noPaymentMethodRequestable', function () {
							that.$emit('update-submit-disabled-state', true);
						});
					} else {
						this.$root.$emit('fatal-error', `Couldn't create Braintree form`);
					}
				});
            },

			deletePaymentMethod() {
            	this.resettablePayment = false
            	this.instance.clearSelectedPaymentMethod();
			},

            requestPaymentMethod() {
                this.instance.requestPaymentMethod((err, payload) => {
                    if (!err) {
	                    console.log('no error');
	                    dataLayer.push({
		                    'event': 'GaEvent',
		                    'EventCategory': 'Payment type selected',
		                    'EventAction': payload.type,
		                    'EventLabel': ''
	                    });
	                    this.$root.$emit('gateway-changed', {
                            gateway: 'braintree',
                            gateway_data: payload.nonce,
		                    device_data: payload.deviceData
                        });
	                    this.resettablePayment = true
                    } else {
                    	console.log('error')
                    	console.log(err);
	                    this.$root.$emit('fatal-error', `Couldn't request payment method`);
	                    this.resettablePayment = false
                    }
                });
            },

	        getUrlParameter(name) {
				name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
				var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
				var results = regex.exec(location.search);
				return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
			}
        }
    }
</script>
