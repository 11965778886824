<template>
  <div id="dropin-container" class="dropin-container col-12 col-lg-8 pl-0"></div>
</template>

<script>
import BraintreeDropIn from 'braintree-web-drop-in';

export default {
  data: function () {
    return {
      instance: null
    }
  },

  mounted() {
    setTimeout(() => {
      this.initBraintreeContainer();
    }, 500);

    this.$root.$on(`checkout::reservation-submitted`, () => {
      this.requestPaymentMethod();
    });

    this.$root.$on(`payment-container::reset`, () => {
      this.reinitBraintreeContainer();
    });
  },

  methods: {
    reinitBraintreeContainer() {
      if (this.instance) {
        this.instance.teardown();
        this.initBraintreeContainer();
      }
    },

    initBraintreeContainer() {
      BraintreeDropIn.create({
        authorization: window.apr.braintree_client_token,
        container: '#dropin-container',
        dataCollector: {
          kount: true
        },
        card: {
          overrides: {
            fields: {
              cvv: {
                placeholder: ' ',
              },
              number: {
                placeholder: '',
              },
              expirationDate: {},
            },
            styles: {
              'input': {
                'font-size': '0.875rem',
                'font-weight': '500',
                'color': '#5a5e6b',
                'font-family': '"Lato", sans-serif'
              },
              'input:focus': {
                'color': '#5a5e6b',
              },
              '.invalid': {}
            }
          }
        }
      }, (createErr, instance) => {
        if (!createErr) {
          this.instance = instance;
        } else {
          this.$root.$emit('fatal-error', `Couldn't create Braintree form`);
        }
      });
    },

    requestPaymentMethod() {
      this.instance.requestPaymentMethod((err, payload) => {
        if (!err) {
          this.$root.$emit('gateway-changed', {
            gateway: 'braintree',
            gateway_data: payload.nonce,
            device_data: payload.deviceData
          });
        } else {
          this.$root.$emit('fatal-error', `Couldn't request payment method`);
        }
      });
    }
  }
}
</script>
